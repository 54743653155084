import React from "react"
import PageSectionWrap from "../PageSectionWrap"
import { Divider, FormControl, FormControlLabel, Grid, Switch, Typography, IconButton } from "@mui/material"
import MDInput from "components/MDInput"
import { useFieldArray } from "react-hook-form"
import { useDispatch } from "react-redux"
import { setAlertMessage } from "redux/globalState"
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material"
import MDButton from "components/MDButton"

const LocationSection = ({ control, watch, setValue, register }) => {
  const dispatch = useDispatch()

  const {
    append: appendLocation,
    fields: locationFields,
    remove: removeLocation,
  } = useFieldArray({
    control: control,
    name: "locationdetails.data",
    keyName: "id",
    shouldUnregister: false,
  })

  const handleRemoveLocation = (index) => {
    if (locationFields.length === 1) {
      dispatch(setAlertMessage({ type: "error", message: "At least one Location is required" }))
      return
    }
    removeLocation(index)
  }

  const handleAddLocation = () => {
    if (locationFields.length >= 5) {
      dispatch(setAlertMessage({ type: "error", message: `Maximum ${5} Locations are only allowed` }))
      return
    }
    appendLocation({ title: "", locationlink: "", locationembed: "" })
  }

  return (
    <PageSectionWrap
      initialOpen={false}
      saveButton
      enabled={
        <FormControlLabel
          sx={{ marginRight: "0px" }}
          onClick={(event) => event.stopPropagation()}
          control={
            <Switch
              color="primary"
              checked={watch("locationdetails.enabled")}
              onChange={(e) => {
                setValue("locationdetails.enabled", e.target.checked)
              }}
            />
          }
          label=""
        />
      }
      header={
        <>
          <Typography fontWeight={500} variant="h5">
            Location Details
          </Typography>
        </>
      }
    >
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "flex-end",
            paddingTop: "12px !important",
          }}
        >
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={watch("locationdetails.enabled")}
                onChange={(e) => {
                  setValue("locationdetails.enabled", e.target.checked)
                }}
              />
            }
            label="Enable"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl variant="outlined" sx={{ width: "100%" }}>
            <MDInput type="text" label="Location Details Title" {...register("locationdetails.title", {})} />
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            paddingTop: "0px !important",
          }}
        >
          <Divider
            sx={{
              my: "1rem",
            }}
          />
        </Grid>

        <Grid item xs={12}>
          {locationFields.map((item, index) => (
            <React.Fragment key={item.id}>
              {index > 0 && (
                <Divider
                  sx={{
                    my: "1rem",
                  }}
                />
              )}
              {index > 0 && (
                <div className="text-end">
                  <IconButton type="button" color="error" onClick={() => handleRemoveLocation(index)}>
                    <RemoveCircleOutline />
                  </IconButton>
                </div>
              )}
              <div className="flex items-center gap-3">
                <FormControl variant="outlined" sx={{ width: "100%" }}>
                  <MDInput
                    type="text"
                    label="Location Link"
                    {...register(`locationdetails.data.${index}.locationlink`, {})}
                  />
                </FormControl>
                <FormControl variant="outlined" sx={{ width: "100%" }}>
                  <MDInput
                    type="text"
                    label="Location Embeded"
                    {...register(`locationdetails.data.${index}.locationembed`, {})}
                  />
                </FormControl>
              </div>
            </React.Fragment>
          ))}
        </Grid>

        <Grid item xs={12}>
          <MDButton
            sx={{
              gap: "0.5rem",
              marginTop: "12px",
            }}
            color="success"
            type="button"
            size="small"
            onClick={handleAddLocation}
          >
            <AddCircleOutline /> Add Location
          </MDButton>
        </Grid>
      </Grid>
    </PageSectionWrap>
  )
}

export default LocationSection
